//security.js
import request from '@/utils/request'
import qs from 'qs'

// 查询服务商个人资料
export function showservices (id,state) {
  let data = {
  id,state
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/Showfws',
    method: 'post',
    data
  })
}
// 查找当前服务商密码
export function showpassword (id) {
  let data = {
  id
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/showfwspwd',
    method: 'post',
    data
  })
}
// 修改服务商密码
export function changepassword (id,password) {
  let data = {
  id,password
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/fwsmodifypwd',
    method: 'post',
    data
  })
};

// 向服务商发送验证码
export function sendfwscode (phone) {
  let data = {
  phone
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/fwsyanzhengma',
    method: 'post',
    data
  })
}

// 绑定手机号
export function bindtel (phone,phonecode,id) {
  let data = {
  phone,phonecode,id
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/addphone',
    method: 'post',
    data
  })
}



