<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="绑定手机号" name="first">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >

          <div v-if="phone == ''" class="nodata">未绑定</div>
          <div v-else class="nodata1">
            <span>已绑定：{{ phone }}</span>
          </div>

          <el-form-item label="手机号" prop="tel" style="width: 40%">
            <el-input
              v-model="ruleForm.tel"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code" style="width: 40%">
            <el-input v-model="ruleForm.code" placeholder="验证码"
              ><i
                v-if="able"
                slot="suffix"
                style="font-style: normal; color: red; cursor: pointer"
                @click="sendcode"
                >{{ btnContent }}</i
              >
              <i
                v-else
                slot="suffix"
                style="font-style: normal; color: red; cursor: pointer"
                >{{ btnContent }}</i
              >
            </el-input>
          </el-form-item>
            <el-button type="danger"  style="width: 100px; background: red; border: red" @click="save('ruleForm')"
              >保存</el-button
            >
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="修改资料" name="second">
        <el-form
          label-width="80px"
          :model="ruleForm2"
          :rules="rules2"
          ref="ruleForm2"
        >
          <el-form-item label="修改头像">
            <label for="headImg">
              <img
                class="photo"
                :src="ruleForm2.headImgUrl"
                alt
                style="cursor: pointer"
              />
            </label>
            <input
              class="file"
              style="display: none"
              id="headImg"
              name="multipartFile"
              type="file"
              @change="up1"
            />
          </el-form-item>
          <el-form-item label="原密码" prop="password" style="width: 40%">
            <el-input
              v-model="ruleForm2.password"
              type="password"
              placeholder="请输入原密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newpassword" style="width: 40%">
            <el-input
              v-model="ruleForm2.newpassword"
              type="password"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="确认密码"
            prop="checkpassword"
            style="width: 40%"
          >
            <el-input
              v-model="ruleForm2.checkpassword"
              type="password"
              placeholder="请输入确认密码"
            ></el-input>
          </el-form-item>
          <el-button
            type="danger"
            style="width: 100px; background: red; border: red"
            @click="ChangePassword('ruleForm2')"
            >保存</el-button
          >
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { showservices, showpassword, changepassword, bindtel, sendfwscode, changefwsavater } from '../../../API/security'
import addImg from "../../../assets/image/upImg.jpg";
export default {
  data () {
    return {
      pwd: "",
      phone:"",
      activeName: 'first', //默认选项卡初始化1
      btnContent: '获取验证码',
      time: 60,//60秒倒计时
      able: true,
      ruleForm: {
        tel: '',
        code: ''
      },
      ruleForm2: {
        headImgUrl: addImg, // 回显地址
        imageUrl: '',//头像url
        password: '',
        newpassword: '',
        checkpassword: ''
      }, rules: {
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }, rules2: {

        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        newpassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        checkpassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' }
        ]
      }

    }
  },
  methods: {
    //发送验证码
    sendcode () {
      let reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/ // 手机号正则验证
      let phoneNum = this.ruleForm.tel // 输入手机号的value
      if (!phoneNum) { //
        // 未输入手机号
        this.$message.error('请输入手机号');
        return
      }
      if (!reg.test(phoneNum)) {
        // 手机号不合法
        this.$message.error('手机号不合法，请重新输入');
        // this.phoneNum = '' // 这个看情况 ，看是否要删除，获取让用用户手机清除
      } else {
        this.btnContent = this.time + "秒后重新发送"
        let timer = setInterval(() => {
          if (this.time === 0) { //  当时间等于 0的时候
            clearInterval(timer) //清除时间
            // this.disabled = false // 让按钮的样式 为登陆状态
            this.btnContent = '重新获取验证码' // 登陆状态的value文本
            this.ruleForm.code = '' // 清除掉验证表单的值
            this.able = true
            this.time = 30

          } else {
            this.btnContent = this.time + "秒后重新发送" // 若时间大于则展示重新获取
            // this.disabled = true // 判断显示文本的状态
            this.able = false
            this.time-- //将时间的一秒秒

          }
        }, 1000)
        sendfwscode(phoneNum).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.$message({
              showClose: true,
              message: '验证码已发送',
              type: 'success'
            });
          } else {
            this.$message.error("发送失败")
          }
        })
      }
    }, checkpass () {//验证新密码和二次密码
      if (this.ruleForm2.newpassword !== this.ruleForm2.checkpassword) {
        this.$message({
          message: '两次密码不一致',
          type: 'warning'
        });
		return false
      }else{
		  return true
	  }
    },
    up1 (e) {//上传头像方法
      //头像
      this.ruleForm2.headImg = e.target.files[0]; //图片文件

      console.log(this.ruleForm2.headImg)
      this.ruleForm2.headImgUrl = URL.createObjectURL(e.target.files[0]); //图片路径回显
      var formdata = new FormData();
      formdata.append('id', localStorage.getItem('FuWuId'));
      console.log(localStorage.getItem('FuWuId'))
      formdata.append('file', this.ruleForm2.headImg);
      console.log(this.ruleForm2.headImg)
      this.$axios({
        method: 'post',
        url: "/facilitator/modifyFwsInfo",
        data: formdata,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.code == 0) {
          this.$message.success("头像修改成功")
		   this.myshowservice()
		   this.$root.$emit('next') //调用父路由中监听的next方法,然后传两个参
        }
      })


    },
    FanHui () {
      this.$emit('back')
    },
    //修改服务商密码
    ChangePassword (ruleForm2) {
		console.log(ruleForm2)
		this.$refs[ruleForm2].validate((valid, obj) => {
		        if (valid) {
				let resp=this.checkpass()
				if(resp){
				 showpassword(localStorage.getItem('FuWuId')).then(res => {
				   this.pwd = res.data.data.password
				   console.log(this.pwd, this.ruleForm2.password)
				   if (this.pwd != this.ruleForm2.password) {
				     this.$message.error("原密码错误")
				     return false
				   } else {
				     return true
				   }
				 }).then(res => {
				   if (res) {
				     changepassword(localStorage.getItem('FuWuId'), this.ruleForm2.newpassword).then(res => {
				       if (res.data.code == 0) {
				         this.$message({
				           showClose: true,
				           message: '密码修改成功',
				           type: 'success'
				         });
				       }
				     })
				   }
				 })
				}
			}
		})
	 
    },
    save (ruleForms) {
	this.$refs[ruleForms].validate((valid, obj) => {
	        if (valid) {
		bindtel(this.ruleForm.tel, this.ruleForm.code, localStorage.getItem('FuWuId')).then(res => {
		  if (res.data.code == -1) {
		    this.$message.error("验证码有误")
		
		  } else {
		    location.reload();
		    this.$message({
		      showClose: true,
		      message: '绑定成功',
		      type: 'success'
		    });
		  }
		})
	}})

     
    },myshowservice(){
		showservices(localStorage.getItem('FuWuId'), 1).then(res => {
          this.phone=res.data.data.phone;
			if(res.data.data.headimg.length!=0){
			  this.ruleForm2.headImgUrl = this.URL + res.data.data.headimg;//显示当前头像
			}
		
		})
	}


  },
  mounted () {
   this.myshowservice()
  }

}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.headerImg {
  object-fit: cover;
  object-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 50px 150px;
}
.headerInput {
  position: absolute;
  top: 220px;
  left: 43%;
  right: 0;
  width: 8%;
  height: 35%;
  border-radius: 50%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.photo {
  width: 150px !important;
  height: 150px !important;
}
.nodata {
  text-align: center;
  line-height: 80px;
  color: red;
  text-align:center;
}
.nodata1 {
  text-align: center;
  line-height: 80px;
  color: red;
  text-align:center;
}
</style>